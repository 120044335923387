import React from 'react'
import { isMobileOnly } from "react-device-detect";
import { useMyContext } from "../Context/context";
import { useLocation } from "react-router-dom";
var HtmlToReactParser = require("html-to-react").Parser;
var htmlToReactParser = new HtmlToReactParser();

export default function News() {
    const { lexisNews } = useMyContext();
    let location = useLocation();
    if (isMobileOnly) {
        if (lexisNews.nombreArchivo !== undefined) {
            window.location.href = "http://esilecstorage.s3.amazonaws.com/biblioteca_silec/SILECNEWS/" + lexisNews.nombreArchivo.substr(2, 4) + "/" + lexisNews.nombreArchivo + ".html"
        }
        return null
    }

    else {
        return (
            <div className="desktop-news-container pb-5">
                {location.pathname === "/" && htmlToReactParser.parse(lexisNews.contenido)}
            </div>

        )
    }
}
