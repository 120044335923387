import React, { useState, useContext } from 'react'

// make a new context
export const MyContext = React.createContext();
export const useMyContext = () => useContext(MyContext);

// create the provider
export const MyProvider = (props) => {

  const [lexisNews, setNews] = useState("news")
  console.log("rendering context rooot config")
  const apiStates = {
    LOADING: "LOADING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
  };

  const [data, setData] = useState({
    state: apiStates.LOADING,
    error: "",
    data: [],
    enabled: true,
  });

  const setPartData = (partialData) => setData({ ...data, ...partialData });


  const fetchData = async (url, bodyQuery) => {
    var fetchedData = "";
    //console.log(apiParameters)

    setPartData({
      state: apiStates.LOADING,
    });
    //console.log("previous to fetch")
    await fetch(url, {
      method: "post",
      body: JSON.stringify(bodyQuery),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) =>
        //console.log(response)
        response.ok
          ? response.json()
          : (fetchedData = {
            state: apiStates.ERROR,
            error: response.status,
            data: null,
          })
      )
      .then((data) => {
        if (data.state !== "ERROR")
          fetchedData = {
            state: apiStates.SUCCESS,
            data,
          };

        //console.log(data)
      })
      .catch((err) => {
        //console.log(err)
        fetchedData = {
          state: apiStates.ERROR,
          error: err.status,
          data: null,
        };
      });
    //console.log(fetchedData)
    return fetchedData;
  };

  React.useEffect(() => {


    fetchData("https://servicessecure.lexis.com.ec/api/v1/lexisnews/findlexisnews").then((data) => {
      console.log("fetched lexis news", data.data);
      if (data.data !== null) {
        setNews(data.data);

      }
    });

  }, [])
  return (
    <MyContext.Provider value={{

      lexisNews,
    }}>
      {props.children}
    </MyContext.Provider>
  )
}


