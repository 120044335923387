import React from "react";
import { MyProvider } from "./Context/context.js";
import { BrowserRouter as Router } from 'react-router-dom'; // Import elements for routing
import News from "./Components/News"
export default function Root(props) {
  return (
    <MyProvider >
      <Router>
        <News />
      </Router>
    </MyProvider>);
}
